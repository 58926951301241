import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-grid-system'
import {RouteProps} from 'react-router'
import {useHistory} from 'react-router-dom'
import PaginationsButton from '../../components/Buttons/PaginationsButton'

import PopUp from '../../components/PopUp'
import {useVotation} from '../../context/VotationContext/Context'
// import {useAuth} from '../../context/AuthContext/Context'
import {
  Criterion,
  ModalCriterion,
  WrapperCriterion,
  ModalCriterionHead,
  WrapperPage,
  XIcon,
} from '../Indicacao/styles'
import * as S from './styles'
import msLogo from '../../assets/images/logos/microsoft.svg'
import InfoIcon from '../../assets/images/icon/info-icon.svg'
import OutlinedButton from '../../components/Buttons/OutlinedButton'
import Realization from '../../components/Realization'

const Votacao: React.FC<RouteProps> = () => {
  const [criterionIsOpen, setCriterionIsOpen] = useState(false)
  const [confirmVoteId, setConfirmVoteId] = useState<string | undefined>()
  const [targetVote, setTargetVote] = useState<{
    id: string
    indication: string
    text: string
  }>()
  const {currentCategory, prevButton, nextButton, vote, currentStep, limitedAccess} =
    useVotation()
  const history = useHistory()

  // const {user} = useAuth()

  useEffect(() => {
    if (!confirmVoteId || !currentCategory?.category?.categoryIndicated) {
      setTargetVote(undefined)
      return
    }

    const newTargetVote = currentCategory.category.categoryIndicated.find(
      (ind) => ind.id === confirmVoteId,
    )

    setTargetVote(newTargetVote)
  }, [confirmVoteId, currentCategory?.category])

  const onClickListItem = (id: string) => {
    if (currentCategory?.vote[0]?.voted) return

    if (confirmVoteId === id) {
      vote(confirmVoteId)
      setConfirmVoteId(undefined)
      return
    }

    setConfirmVoteId(id)
  }

  useEffect(() => {
    if (!currentStep) return

    if (currentStep?.page !== history.location.pathname) {
      history.push(currentStep?.page)
    }
  }, [currentStep])


  if (
    !currentCategory ||
    !currentCategory.category ||
    !currentCategory.vote ||
    !currentCategory.category.categoryIndicated
  )
    return <></>

  return (
    <>
      <WrapperPage>
        <Container>
          <Row>
            <Col xs={12}>
              <S.WrapperGiveBack>
                <p>Give Back por</p>
                <img src={msLogo} />
              </S.WrapperGiveBack>
            </Col>
          </Row>
          {targetVote ? (
            <S.ConfirmVoteWrapper>
              <h2>{targetVote?.indication}</h2>
              <p>{targetVote?.text}</p>
              <S.WrapperConfirmButtons>
                <S.ConfirmButton onClick={() => onClickListItem(targetVote.id)}>
                  Confirmar
                </S.ConfirmButton>
                <S.BackButton onClick={() => setConfirmVoteId(undefined)}>
                  Voltar
                </S.BackButton>
              </S.WrapperConfirmButtons>
            </S.ConfirmVoteWrapper>
          ) : (
            <>
              <Row>
                <Col xs={12}>
                  <S.TitleWrapper>
                    <S.Title>{currentCategory.category.name}</S.Title>
                  </S.TitleWrapper>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <S.SubTitleWrapper>
                    <OutlinedButton
                      isGray
                      onClick={() => setCriterionIsOpen(!criterionIsOpen)}>
                      CRITÉRIOS DA CATEGORIA
                    </OutlinedButton>
                    <ModalCriterion
                      positionTop={'60px'}
                      criterionIsOpen={criterionIsOpen}>
                      <XIcon
                        onClick={() => setCriterionIsOpen(!criterionIsOpen)}
                      />
                      <ModalCriterionHead>
                        <p>Critérios da Categoria</p>
                      </ModalCriterionHead>
                      {currentCategory.category.requirements.map(
                        (criterion) => {
                          return (
                            <WrapperCriterion key={criterion.order}>
                              <Criterion>{criterion.requirement}</Criterion>
                            </WrapperCriterion>
                          )
                        },
                      )}
                    </ModalCriterion>
                  </S.SubTitleWrapper>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <S.ListWrapper transparent={criterionIsOpen}>
                    {currentCategory.category.categoryIndicated.map(
                      ({id, indication}) => (
                        <S.ListItem
                          voted={id === currentCategory.vote[0]?.indicationId}
                          onClick={() => onClickListItem(id)}
                          key={id}
                          limitAccess={limitedAccess}>
                          {indication}
                          {/* <img src={InfoIcon} /> */}
                        </S.ListItem>
                      ),
                    )}
                  </S.ListWrapper>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <S.PaginationWrapper>
                    <PaginationsButton
                      prevButton={prevButton}
                      nextButton={nextButton}
                    />
                  </S.PaginationWrapper>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </WrapperPage>
      <Realization />
      <PopUp />
    </>
  )
}

export default Votacao
