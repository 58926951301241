import React from 'react'

import * as S from './styles'

// import {Container} from '../../styles/globals'

// import {useVotation} from '../../context/VotationContext/Context'

const TopWarning: React.FC = () => {

  const showWarning = false;
  const stage = 3;
  const message: { [key: number]: string } = {
    1: "As indicações da 1ª fase serão realizadas APENAS pela Academia Startup Awards.",
    2: "o Startup Awards entrou na fase de votação ABERTA ao público. Vote agora! Votações abertas até 27/10/23.",
    3: "As votações da 3ª fase serão realizadas APENAS pela Academia Startup Awards. "
  };

  return showWarning ? (
    <S.TopWarning>
      <p><b>Atenção:</b> {stage in message ? message[stage] : ""}</p>
    </S.TopWarning>
  ) : <></>;
}

export default TopWarning
